/* eslint-disable functional/no-mixed-type, functional/no-return-void, functional/no-expression-statement */
export function createDinero(_ref) {
  var calculator = _ref.calculator,
      onCreate = _ref.onCreate;
  return function dinero(_ref2) {
    var amount = _ref2.amount,
        currency = _ref2.currency,
        _ref2$scale = _ref2.scale,
        scale = _ref2$scale === void 0 ? currency.exponent : _ref2$scale;
    onCreate === null || onCreate === void 0 ? void 0 : onCreate({
      amount: amount,
      currency: currency,
      scale: scale
    });
    return {
      calculator: calculator,
      create: dinero,
      toJSON: function toJSON() {
        return {
          amount: amount,
          currency: currency,
          scale: scale
        };
      }
    };
  };
}