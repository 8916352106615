/**
 * Returns an number to the power of an exponent.
 *
 * @param base - The base number.
 * @param exponent - The exponent to raise the base to.
 *
 * @returns The base to the power of the exponent.
 */
export var power = function power(base, exponent) {
  return Math.pow(base, exponent);
};