import { greaterThan } from '../utils';
export function transformScale(calculator) {
  var greaterThanFn = greaterThan(calculator);
  return function transformScaleFn() {
    for (var _len = arguments.length, _ref = new Array(_len), _key = 0; _key < _len; _key++) {
      _ref[_key] = arguments[_key];
    }

    var dineroObject = _ref[0],
        newScale = _ref[1];

    var _dineroObject$toJSON = dineroObject.toJSON(),
        amount = _dineroObject$toJSON.amount,
        currency = _dineroObject$toJSON.currency,
        scale = _dineroObject$toJSON.scale;

    var isNewScaleLarger = greaterThanFn(newScale, scale);
    var operation = isNewScaleLarger ? calculator.multiply : calculator.integerDivide;
    var terms = isNewScaleLarger ? [newScale, scale] : [scale, newScale];
    var factor = calculator.power(currency.base, calculator.subtract.apply(calculator, terms));
    return dineroObject.create({
      amount: operation(amount, factor),
      currency: currency,
      scale: newScale
    });
  };
}