/* eslint-disable functional/no-let, functional/no-loop-statement, functional/immutable-data, functional/no-expression-statement */
import { equal } from './equal';
import { greaterThan } from './greaterThan';
import { greaterThanOrEqual } from './greaterThanOrEqual';
import { lessThan } from './lessThan';

/**
 * Returns a distribute function.
 *
 * @param calculator - The calculator to use.
 *
 * @returns The distribute function.
 */
export function distribute(calculator) {
  return function (value, ratios) {
    var equalFn = equal(calculator);
    var greaterThanFn = greaterThan(calculator);
    var lessThanFn = lessThan(calculator);
    var greaterThanOrEqualFn = greaterThanOrEqual(calculator);
    var zero = calculator.zero();
    var one = calculator.increment(zero);
    var total = ratios.reduce(function (a, b) {
      return calculator.add(a, b);
    }, zero);

    if (equalFn(total, zero)) {
      return ratios;
    }

    var remainder = value;
    var shares = ratios.map(function (ratio) {
      var share = calculator.integerDivide(calculator.multiply(value, ratio), total) || zero;
      remainder = calculator.subtract(remainder, share);
      return share;
    });
    var isPositive = greaterThanOrEqualFn(value, zero);
    var compare = isPositive ? greaterThanFn : lessThanFn;
    var amount = isPositive ? one : calculator.decrement(zero);
    var i = 0;

    while (compare(remainder, zero)) {
      if (!equalFn(ratios[i], zero)) {
        shares[i] = calculator.add(shares[i], amount);
        remainder = calculator.subtract(remainder, amount);
      }

      i++;
    }

    return shares;
  };
}